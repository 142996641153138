// Color variables for the sidenav

// Z index variables
$zindex-content: 1037;
$zindex-sidenav: 1038;
$zindex-topnav: 1039;

// -- Base Sidenav Dark
$sidenav-dark-bg: $gray-900;
$sidenav-dark-color: fade-out($white, 0.5);
$sidenav-dark-heading-color: fade-out($white, 0.75);
$sidenav-dark-link-color: fade-out($white, 0.5);
$sidenav-dark-link-active-color: $white;
$sidenav-dark-icon-color: fade-out($white, 0.75);
$sidenav-dark-footer-bg: fade-out($black, 0.75);
$sidenav-dark-bg: lighten($gray-900, 3%);

// -- Base Sidenav Light
$sidenav-light-bg: $white;
$sidenav-light-color: $gray-900;
$sidenav-light-heading-color: $gray-500;
$sidenav-light-link-color: $sidenav-light-color;
$sidenav-light-link-active-color: $black;
$sidenav-light-icon-color: $gray-500;
$sidenav-light-footer-bg: fade-out($gray-900, 0.95);

// -- Sidenav Border Offset
$sidenav-nested-border-offest: 0.5625rem;

// Color variables for the topnav
$topnav-dark-toggler-color: fade-out($white, 0.5);
$topnav-light-toggler-color: $gray-900;
