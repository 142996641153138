// Background color utilities

// Adds a background gradient with a $primary color fallback
.bg-gradient-primary-to-secondary {
    background-color: $primary;
    background-image: linear-gradient(135deg, $primary 0%, fade-out($secondary, 0.2) 100%);
}

// Grayscale background utilities
.bg-gray-100 {
    background-color: $gray-100 !important;
}

.bg-gray-200 {
    background-color: $gray-200 !important;
}

.bg-gray-300 {
    background-color: $gray-300 !important;
}

.bg-gray-400 {
    background-color: $gray-400 !important;
}

.bg-gray-500 {
    background-color: $gray-500 !important;
}

.bg-gray-600 {
    background-color: $gray-600 !important;
}

.bg-gray-700 {
    background-color: $gray-700 !important;
}

.bg-gray-800 {
    background-color: $gray-800 !important;
}

.bg-gray-900 {
    background-color: $gray-900 !important;
}

.bg-white-25 {
    background-color: fade-out($white, 0.75) !important;
}

// Page header style when using a background image
.bg-img-cover {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

// Page header style when using a background texture
.bg-img-repeat {
    background-position: top left;
    background-repeat: repeat;
}

.overlay {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // Fallbacks
        background-color: $black;
        opacity: 0.5;
    }
}

@each $color, $value in $theme-colors {
    .overlay-#{$color}:before {
        background-color: $value !important;
    }
}

.overlay-10 {
    &:before {
        opacity: 0.1;
    }
}

.overlay-20 {
    &:before {
        opacity: 0.2;
    }
}

.overlay-30 {
    &:before {
        opacity: 0.3;
    }
}

.overlay-40 {
    &:before {
        opacity: 0.4;
    }
}

.overlay-50 {
    &:before {
        opacity: 0.5;
    }
}

.overlay-60 {
    &:before {
        opacity: 0.6;
    }
}

.overlay-70 {
    &:before {
        opacity: 0.7;
    }
}

.overlay-80 {
    &:before {
        opacity: 0.8;
    }
}

.overlay-90 {
    &:before {
        opacity: 0.9;
    }
}


.border-lg {
    border-width: 0.25rem !important;
}

.border-top-lg {
    border-top-width: 0.25rem !important;
}

.border-end-lg {
    border-right-width: 0.25rem !important;
}

.border-bottom-lg {
    border-bottom-width: 0.25rem !important;
}

.border-start-lg {
    border-left-width: 0.25rem !important;
}

.border-primary {
    border-color: #0061f2 !important;
}

.border-secondary {
    border-color: #6900c7 !important;
}

.border-success {
    border-color: #00ac69 !important;
}

.border-info {
    border-color: #00cfd5 !important;
}

.border-warning {
    border-color: #f4a100 !important;
}

.border-danger {
    border-color: #e81500 !important;
}

.border-light {
    border-color: #f2f6fc !important;
}

.border-dark {
    border-color: #212832 !important;
}

.border-black {
    border-color: #000 !important;
}

.border-white {
    border-color: #fff !important;
}

.border-red {
    border-color: #e81500 !important;
}

.border-orange {
    border-color: #f76400 !important;
}

.border-yellow {
    border-color: #f4a100 !important;
}

.border-green {
    border-color: #00ac69 !important;
}

.border-teal {
    border-color: #00ba94 !important;
}

.border-cyan {
    border-color: #00cfd5 !important;
}

.border-blue {
    border-color: #0061f2 !important;
}

.border-indigo {
    border-color: #5800e8 !important;
}

.border-purple {
    border-color: #6900c7 !important;
}

.border-pink {
    border-color: #e30059 !important;
}

.border-red-soft {
    border-color: #f1e0e3 !important;
}

.border-orange-soft {
    border-color: #f3e7e3 !important;
}

.border-yellow-soft {
    border-color: #f2eee3 !important;
}

.border-green-soft {
    border-color: #daefed !important;
}

.border-teal-soft {
    border-color: #daf0f2 !important;
}

.border-cyan-soft {
    border-color: #daf2f8 !important;
}

.border-blue-soft {
    border-color: #dae7fb !important;
}

.border-indigo-soft {
    border-color: #e3ddfa !important;
}

.border-purple-soft {
    border-color: #e4ddf7 !important;
}

.border-pink-soft {
    border-color: #f1ddec !important;
}

.border-primary-soft {
    border-color: #dae7fb !important;
}

.border-secondary-soft {
    border-color: #e4ddf7 !important;
}

.border-success-soft {
    border-color: #daefed !important;
}

.border-info-soft {
    border-color: #daf2f8 !important;
}

.border-warning-soft {
    border-color: #f2eee3 !important;
}

.border-danger-soft {
    border-color: #f1e0e3 !important;
}

.border-start-primary {
    border-left-color: #0061f2 !important;
}

.border-start-secondary {
    border-left-color: #6900c7 !important;
}

.border-start-success {
    border-left-color: #00ac69 !important;
}

.border-start-info {
    border-left-color: #00cfd5 !important;
}

.border-start-warning {
    border-left-color: #f4a100 !important;
}

.border-start-danger {
    border-left-color: #e81500 !important;
}

.border-start-light {
    border-left-color: #f2f6fc !important;
}

.border-start-dark {
    border-left-color: #212832 !important;
}

.border-start-black {
    border-left-color: #000 !important;
}

.border-start-white {
    border-left-color: #fff !important;
}

.border-start-red {
    border-left-color: #e81500 !important;
}

.border-start-orange {
    border-left-color: #f76400 !important;
}

.border-start-yellow {
    border-left-color: #f4a100 !important;
}

.border-start-green {
    border-left-color: #00ac69 !important;
}

.border-start-teal {
    border-left-color: #00ba94 !important;
}

.border-start-cyan {
    border-left-color: #00cfd5 !important;
}

.border-start-blue {
    border-left-color: #0061f2 !important;
}

.border-start-indigo {
    border-left-color: #5800e8 !important;
}

.border-start-purple {
    border-left-color: #6900c7 !important;
}

.border-start-pink {
    border-left-color: #e30059 !important;
}

.border-start-red-soft {
    border-left-color: #f1e0e3 !important;
}

.border-start-orange-soft {
    border-left-color: #f3e7e3 !important;
}

.border-start-yellow-soft {
    border-left-color: #f2eee3 !important;
}

.border-start-green-soft {
    border-left-color: #daefed !important;
}

.border-start-teal-soft {
    border-left-color: #daf0f2 !important;
}

.border-start-cyan-soft {
    border-left-color: #daf2f8 !important;
}

.border-start-blue-soft {
    border-left-color: #dae7fb !important;
}

.border-start-indigo-soft {
    border-left-color: #e3ddfa !important;
}

.border-start-purple-soft {
    border-left-color: #e4ddf7 !important;
}

.border-start-pink-soft {
    border-left-color: #f1ddec !important;
}

.border-start-primary-soft {
    border-left-color: #dae7fb !important;
}

.border-start-secondary-soft {
    border-left-color: #e4ddf7 !important;
}

.border-start-success-soft {
    border-left-color: #daefed !important;
}

.border-start-info-soft {
    border-left-color: #daf2f8 !important;
}

.border-start-warning-soft {
    border-left-color: #f2eee3 !important;
}

.border-start-danger-soft {
    border-left-color: #f1e0e3 !important;
}

.border-end-primary {
    border-right-color: #0061f2 !important;
}

.border-end-secondary {
    border-right-color: #6900c7 !important;
}

.border-end-success {
    border-right-color: #00ac69 !important;
}

.border-end-info {
    border-right-color: #00cfd5 !important;
}

.border-end-warning {
    border-right-color: #f4a100 !important;
}

.border-end-danger {
    border-right-color: #e81500 !important;
}

.border-end-light {
    border-right-color: #f2f6fc !important;
}

.border-end-dark {
    border-right-color: #212832 !important;
}

.border-end-black {
    border-right-color: #000 !important;
}

.border-end-white {
    border-right-color: #fff !important;
}

.border-end-red {
    border-right-color: #e81500 !important;
}

.border-end-orange {
    border-right-color: #f76400 !important;
}

.border-end-yellow {
    border-right-color: #f4a100 !important;
}

.border-end-green {
    border-right-color: #00ac69 !important;
}

.border-end-teal {
    border-right-color: #00ba94 !important;
}

.border-end-cyan {
    border-right-color: #00cfd5 !important;
}

.border-end-blue {
    border-right-color: #0061f2 !important;
}

.border-end-indigo {
    border-right-color: #5800e8 !important;
}

.border-end-purple {
    border-right-color: #6900c7 !important;
}

.border-end-pink {
    border-right-color: #e30059 !important;
}

.border-end-red-soft {
    border-right-color: #f1e0e3 !important;
}

.border-end-orange-soft {
    border-right-color: #f3e7e3 !important;
}

.border-end-yellow-soft {
    border-right-color: #f2eee3 !important;
}

.border-end-green-soft {
    border-right-color: #daefed !important;
}

.border-end-teal-soft {
    border-right-color: #daf0f2 !important;
}

.border-end-cyan-soft {
    border-right-color: #daf2f8 !important;
}

.border-end-blue-soft {
    border-right-color: #dae7fb !important;
}

.border-end-indigo-soft {
    border-right-color: #e3ddfa !important;
}

.border-end-purple-soft {
    border-right-color: #e4ddf7 !important;
}

.border-end-pink-soft {
    border-right-color: #f1ddec !important;
}

.border-end-primary-soft {
    border-right-color: #dae7fb !important;
}

.border-end-secondary-soft {
    border-right-color: #e4ddf7 !important;
}

.border-end-success-soft {
    border-right-color: #daefed !important;
}

.border-end-info-soft {
    border-right-color: #daf2f8 !important;
}

.border-end-warning-soft {
    border-right-color: #f2eee3 !important;
}

.border-end-danger-soft {
    border-right-color: #f1e0e3 !important;
}

.border-top-primary {
    border-top-color: #0061f2 !important;
}

.border-top-secondary {
    border-top-color: #6900c7 !important;
}

.border-top-success {
    border-top-color: #00ac69 !important;
}

.border-top-info {
    border-top-color: #00cfd5 !important;
}

.border-top-warning {
    border-top-color: #f4a100 !important;
}

.border-top-danger {
    border-top-color: #e81500 !important;
}

.border-top-light {
    border-top-color: #f2f6fc !important;
}

.border-top-dark {
    border-top-color: #212832 !important;
}

.border-top-black {
    border-top-color: #000 !important;
}

.border-top-white {
    border-top-color: #fff !important;
}

.border-top-red {
    border-top-color: #e81500 !important;
}

.border-top-orange {
    border-top-color: #f76400 !important;
}

.border-top-yellow {
    border-top-color: #f4a100 !important;
}

.border-top-green {
    border-top-color: #00ac69 !important;
}

.border-top-teal {
    border-top-color: #00ba94 !important;
}

.border-top-cyan {
    border-top-color: #00cfd5 !important;
}

.border-top-blue {
    border-top-color: #0061f2 !important;
}

.border-top-indigo {
    border-top-color: #5800e8 !important;
}

.border-top-purple {
    border-top-color: #6900c7 !important;
}

.border-top-pink {
    border-top-color: #e30059 !important;
}

.border-top-red-soft {
    border-top-color: #f1e0e3 !important;
}

.border-top-orange-soft {
    border-top-color: #f3e7e3 !important;
}

.border-top-yellow-soft {
    border-top-color: #f2eee3 !important;
}

.border-top-green-soft {
    border-top-color: #daefed !important;
}

.border-top-teal-soft {
    border-top-color: #daf0f2 !important;
}

.border-top-cyan-soft {
    border-top-color: #daf2f8 !important;
}

.border-top-blue-soft {
    border-top-color: #dae7fb !important;
}

.border-top-indigo-soft {
    border-top-color: #e3ddfa !important;
}

.border-top-purple-soft {
    border-top-color: #e4ddf7 !important;
}

.border-top-pink-soft {
    border-top-color: #f1ddec !important;
}

.border-top-primary-soft {
    border-top-color: #dae7fb !important;
}

.border-top-secondary-soft {
    border-top-color: #e4ddf7 !important;
}

.border-top-success-soft {
    border-top-color: #daefed !important;
}

.border-top-info-soft {
    border-top-color: #daf2f8 !important;
}

.border-top-warning-soft {
    border-top-color: #f2eee3 !important;
}

.border-top-danger-soft {
    border-top-color: #f1e0e3 !important;
}

.border-bottom-primary {
    border-bottom-color: #0061f2 !important;
}

.border-bottom-secondary {
    border-bottom-color: #6900c7 !important;
}

.border-bottom-success {
    border-bottom-color: #00ac69 !important;
}

.border-bottom-info {
    border-bottom-color: #00cfd5 !important;
}

.border-bottom-warning {
    border-bottom-color: #f4a100 !important;
}

.border-bottom-danger {
    border-bottom-color: #e81500 !important;
}

.border-bottom-light {
    border-bottom-color: #f2f6fc !important;
}

.border-bottom-dark {
    border-bottom-color: #212832 !important;
}

.border-bottom-black {
    border-bottom-color: #000 !important;
}

.border-bottom-white {
    border-bottom-color: #fff !important;
}

.border-bottom-red {
    border-bottom-color: #e81500 !important;
}

.border-bottom-orange {
    border-bottom-color: #f76400 !important;
}

.border-bottom-yellow {
    border-bottom-color: #f4a100 !important;
}

.border-bottom-green {
    border-bottom-color: #00ac69 !important;
}

.border-bottom-teal {
    border-bottom-color: #00ba94 !important;
}

.border-bottom-cyan {
    border-bottom-color: #00cfd5 !important;
}

.border-bottom-blue {
    border-bottom-color: #0061f2 !important;
}

.border-bottom-indigo {
    border-bottom-color: #5800e8 !important;
}

.border-bottom-purple {
    border-bottom-color: #6900c7 !important;
}

.border-bottom-pink {
    border-bottom-color: #e30059 !important;
}

.border-bottom-red-soft {
    border-bottom-color: #f1e0e3 !important;
}

.border-bottom-orange-soft {
    border-bottom-color: #f3e7e3 !important;
}

.border-bottom-yellow-soft {
    border-bottom-color: #f2eee3 !important;
}

.border-bottom-green-soft {
    border-bottom-color: #daefed !important;
}

.border-bottom-teal-soft {
    border-bottom-color: #daf0f2 !important;
}

.border-bottom-cyan-soft {
    border-bottom-color: #daf2f8 !important;
}

.border-bottom-blue-soft {
    border-bottom-color: #dae7fb !important;
}

.border-bottom-indigo-soft {
    border-bottom-color: #e3ddfa !important;
}

.border-bottom-purple-soft {
    border-bottom-color: #e4ddf7 !important;
}

.border-bottom-pink-soft {
    border-bottom-color: #f1ddec !important;
}

.border-bottom-primary-soft {
    border-bottom-color: #dae7fb !important;
}

.border-bottom-secondary-soft {
    border-bottom-color: #e4ddf7 !important;
}

.border-bottom-success-soft {
    border-bottom-color: #daefed !important;
}

.border-bottom-info-soft {
    border-bottom-color: #daf2f8 !important;
}

.border-bottom-warning-soft {
    border-bottom-color: #f2eee3 !important;
}

.border-bottom-danger-soft {
    border-bottom-color: #f1e0e3 !important;
}