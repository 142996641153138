// This file is intentionally blank
// Use this file to override SCSS within the project, or to add new SCSS for creating your own CSS components
.alert-pad-03 {
    padding: 0.3rem;
    border: 1px solid transparent;
    border-radius: 0.35rem;
}

.alert-pad-05 {
    padding: 0.5rem;
    border: 1px solid transparent;
    border-radius: 0.35rem;
}