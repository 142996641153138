// Override and add to Bootstrap card style defaults

.card {
    box-shadow: $box-shadow;
    .card-header {
        font-weight: 500;
    }
    // Sets the color of the card header to primary unless a background utility class is being used
    &:not([class*='bg-']) {
        .card-header {
            color: $black;
        }
    }
}

.card.bg-dark {
    .card-header,
    .card-footer {
        border-color: fade-out($white, 0.85);
    }
}

// Override styling
.card .card-header .card-header-tabs .nav-link.active {
    background-color: $white;
    border-bottom-color: $white;
}
