.table-black {
    background-color: black !important;
    color: white;
}

.bg-black {
    background-color: black;
    color: white;
}

.dataTables_scrollBody {
    position: relative;
    overflow: auto;
    max-height: 500px;
    width: 100%;
}

table {
    margin-bottom: 0 !important;
}

.custom-checkbox .custom-control-label {
    font-size: 14px;
    padding: 4px 0 0 0;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    border-color: #000;
    background-color: rgb(31, 28, 28);
}

.text-right {
    text-align: right;
}

body {
    background-color: white;
}

.cal-header {
    background-color: black;
    color: white;
}

.cal-cell:hover {
    color: black !important;
}

.btn-outline-black-cs {
    color: #000;
    border-color: #000;
}

.swal2-styled.swal2-confirm {
    background-color: #000 !important;
    width: 120px !important;
}

.swal2-modal {
    border-radius: 100px !important;
}